<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Labot/skatīt ārējo integrāciju"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-col cols="12" class="text-center" v-if="!users && !options">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Professionālās pilnveides kurss tika veiksmīgi labots!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p v-for="(e, index) in errors" :key="index"> {{ e[0] }} </p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-form @submit="onSubmit" enctype="multipart/form-data">
            <b-form-group
                id="input-group-5"
                label="Nosaukums:"
                label-for="input-5"
            >

              <b-form-input
                  id="input-5"
                  type="text"
                  v-model="$v.form.name.$model"
                  aria-describedby="input-5-live-feedback"
                  :state="validatePositions($v.form.name)"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-5-live-feedback"
              >Nepareizs nosaukums</b-form-invalid-feedback>

            </b-form-group>

            <b-form-group
                id="input-group-5"
                label="Adrese:"
                label-for="input-5"
            >

              <b-form-input
                  id="input-5"
                  type="text"
                  v-model="$v.form.endpoint.$model"
                  aria-describedby="input-5-live-feedback"
                  :state="validatePositions($v.form.endpoint)"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-5-live-feedback"
              >Nepareiza adrese</b-form-invalid-feedback>

            </b-form-group>

            <b-form-group
                id="input-group-5"
                label="Autorizācijas atslēga:"
                label-for="input-5"
            >

              <b-form-textarea
                  id="input-5"
                  type="date"
                  v-model="$v.form.token.$model"
                  rows="6"
                  max-rows="6"
                  aria-describedby="input-5-live-feedback"
                  :state="validatePositions($v.form.token)"
              ></b-form-textarea>
              <b-form-invalid-feedback
                  id="input-5-live-feedback"
              >Nepareiza autorizācijas atslēga</b-form-invalid-feedback>

            </b-form-group>


            <b-form-group
                id="input-group-1"
                label="Lietotāju grupa:"
                label-for="input-1"
            >
              <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="form.user_group"
                  name="checkbox-group-1"
                  :state="validatePositions($v.form.user_group)"
              >
                <b-form-checkbox :value="role.id" v-for="(role, index) of roles" :key="index">{{ role.name }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <b-form-group
                id="input-group-1"
                label="Status:"
                label-for="input-1"
                v-model="form.status"
                :state="validatePositions($v.form.status)"
            >
              <b-form-checkbox value="true" v-model="form.status">Ieslēgts</b-form-checkbox>
            </b-form-group>
            <b-button type="submit" variant="success">Saglabāt</b-button>
            <b-button variant="warning" class="ml-1" :to="{ name: 'SynchronizationsList' }">Atpakaļ</b-button>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jsonToFormData from '@ajoelp/json-to-formdata';
import {mapActions, mapGetters} from "vuex";
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: "Create",
  data() {
    return {
      users: [],
      selectedUser: null,
      media: process.env.VUE_APP_MEDIA_URL,
      form: {
        name: null,
        token: null,
        endpoint: null,
        user_group: [],
        status: false,
      },
      roles: [],
      created: null,
      dismissSecs: 5,
      dismissCountDown: 0,
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      token: {
        required,
      },
      endpoint: {
        required,
      },
      user_group: {
        required,
      },
      status: {
        required,
      }
    },
  },
  mounted () {
    this.getFormData()
    axios.get('/roles').then(response => {
      this.roles = response.data.data;
    })
  },
  computed: {
    ...mapGetters ({
      options: "Options/options",
    })
  },
  methods: {
    ...mapActions ({
      fetchOptions: "Options/fetchOptions"
    }),
    onSubmit(event) {
      event.preventDefault()

      this.errors = [];

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.errors.push('Nav aizpildīti visi lauki')
        window.scrollTo(0, 0);
        return;
      }

      axios.put('/synchronizations/'+this.$route.params.id, this.form).then(response => {
        this.created = 'success';
        this.getFormData();
      }).catch(e => {
        this.created = 'error';
        this.errors = e.response.data.errors
      })

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      this.dismissCountDown = 5;
    },
    changeName(value) {
      if(value !== 'Nav') {
        let user = (this.users).find((e) => e.name + ' ' + e.surname === value)
        if(user !== undefined) {
          this.form.user_id = user.id
        }
        console.log(value)

      } else {
        this.form.user_id = null
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    onFileChange(event) {
      this.form.files = event.target.files
    },
    validateState(name) {
      // console.log(name)

      const {$dirty, $error} = this.$v.form[name];

      return $dirty ? !$error : null;
    },
    validatePositions(object) {
      // console.log(object)

      const {$dirty, $error} = object;

      return $dirty ? !$error : null;
    },
    getFormData() {
      axios.get('/synchronizations/'+this.$route.params.id).then(response => {
        console.log(response)
        let data = response.data.data
        this.form.name = data.name
        this.form.endpoint = data.endpoint
        this.form.token = data.token
        this.form.user_group = JSON.parse(data.user_group)
        this.form.status = data.status
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>